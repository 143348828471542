<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import Generator from "@/router/views/konfigurasi/dokumen-muk/generator/main-generator";
// import moment from "moment";

export default {
    page: {
        title: "Daftar Dokumen MUK",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
        Generator,
    },
    data() {
        return {
            title: "Konfigurasi Dokumen MUK",
            items: [
                {
                    text: "Daftar Dokumen MUK",
                    href: "/konfigurasi-dokumen-muk",
                },
                {
                    text: "Konfigurasi Dokumen MUK",
                    active: true,
                },
            ],
            // Var page
            id: this.$route.params.id,
            detail: null,
        };
    },
    mounted() {
        this.getDetail();
    },
    methods: {
        getDetail() {
            let self = this;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-dokumen-muk/detail",
                params: {
                    id: self.id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.detail = response_data_fix;
                        console.log(response_data_fix);
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col text-end">
                                <!-- <router-link to="#" class="btn btn-sm btn-primary m-1"><i class="fa fa-plus"></i> Tambah Materi Uji Kompetensi</router-link> -->
                            </div>
                        </div>
                        <div class="row">
                            <Generator :vue_form_generator="detail?.vue_form_generator" :dokumen_id="id"></Generator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
